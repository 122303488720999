/* eslint-disable no-console */
import {
    FORM_EVENTS,
    FORM_SCOPES,
    LANDING_PAGE_SOURCE_TYPE,
    RECAPTCHA_ACTION_TOKEN,
    SMART_FORM_DISPLAY_TYPES,
} from './keap-forms.constants';
import { KeapFormState } from './keap-form-state';
import { inspectForm } from './inspector/form-inspector';
import { findHostingVersion, findParentScopes, findSuccessPage } from '../keap-hosting-util';
import { KEAP_ATTRIBUTES } from '@/hosting/hosting.constants';

import { amplitude, reporting } from '@/hosting/keap-analytics';
import { KEAP_EVENT_NAMES, KEAP_EVENT_PROPERTIES } from '@/hosting/keap-analytics/keap-analytics.constants';

import {
    formInputOptionValues,
    getPublicFormInfo,
    submitSmartForm,
} from '@/hosting/keap-forms-processing/smart-form-api';
import { RecaptchaHelper } from '@/hosting/keap-forms-processing/recaptcha-helper';
import snakeCase from 'lodash/snakeCase';

/**
 * This class encapsulates logic for an HTML form: field binding, recaptcha, validation, and submission.
 *
 * @property {HTMLElement} element The form element used to build this handler
 * @property {?string} slug The smart-forms-api slug for this form.
 * @property {?string} tenantId The tenantId this from represents
 * @property {?object} scopes The scopes for this form: tenantId, siteId, pageId, etc.
 * @property {?string} successRedirect The URL to send the user to after a successful form submission
 * @property {KeapFormState} The form state data, including registered fields, for this form
 * @property {Promise<object>} formLoadPromise The result of loading this form from the smart-forms-api
 */
export class KeapPublicFormHandler {
    /**
     * @param {HTMLElement} keapFormElement The <keap-form> element
     * @param {HTMLFormElement} htmlFormElement The raw <form> element
     */
    constructor(keapFormElement, htmlFormElement) {
        this.hasNotifiedFirstInteraction = false;
        this.formElement = htmlFormElement;
        this.element = keapFormElement;
        this.slug = keapFormElement.getAttribute(KEAP_ATTRIBUTES.formSlug);
        this.scopes = findParentScopes(keapFormElement);
        this.successRedirect = keapFormElement.getAttribute(KEAP_ATTRIBUTES.formSuccessRedirect) || findSuccessPage(keapFormElement);
        this.successTarget = keapFormElement.getAttribute(KEAP_ATTRIBUTES.formSuccessTarget) || '_self';

        this.formState = new KeapFormState({
            onFieldValueChanged: (event) => {
                if (!this.hasNotifiedFirstInteraction) {
                    this.hasNotifiedFirstInteraction = true;

                    amplitude.logEvent(KEAP_EVENT_NAMES.formStarted, {
                        ...this.scopes,
                        [KEAP_EVENT_PROPERTIES.formSlug]: this.slug,
                    });
                }
                this.dispatchEvent(FORM_EVENTS.fieldValueChanged, event);
            },
        });

        if (this.slug) {
            const urlForm = htmlFormElement.action;
            const infContactKeyValue = htmlFormElement.action.includes('?') ? urlForm?.split('?')[1]?.split('=')[1] : '';

            const formInfoPromise = getPublicFormInfo(this.slug, infContactKeyValue);

            this.recaptchaHelperPromise = formInfoPromise.then(({ recaptchaSiteKey }) => {
                return RecaptchaHelper(recaptchaSiteKey);
            });
            this.formIdPromise = formInfoPromise.then((formInfo) => formInfo?.form?.id);

            const isInfContactKeyExist = Boolean(infContactKeyValue);

            formInfoPromise.then((formInfo) => {
                this.initializeFormDropdowns(formInfo, this.formElement, isInfContactKeyExist);
            });

            if (htmlFormElement.action.includes('?')) {
                this.handlingAutofillForm(formInfoPromise);
            }
        }
    }

    initializeFormDropdowns(formInfo, formElement, isInfContactKeyExist) {
        this.addSelectListeners(formInfo?.form, formElement, isInfContactKeyExist);

        this.loadSelectOptions(formInfo?.form, formElement);
    }

    addSelectListeners(form, formElement, isInfContactKeyExist) {
        for (let index = 0; index < formElement.length; index++) {
            if (formElement[index] && formElement[index].nodeName === 'SELECT' && formElement[index]?.name.includes('countryCode')) {
                const targetSelector = `[name="${formElement[index]?.name.replace('countryCode', 'region')}"]`;
                const dropdown = document.querySelectorAll(targetSelector);

                if (dropdown.length > 0) {
                    formElement[index].setAttribute('data-form-id', form?.id);
                    formElement[index].setAttribute('data-target-selector', targetSelector);
                    formElement[index].addEventListener('change', async function (event) {
                        const formId = event.currentTarget.getAttribute('data-form-id');
                        const targetSelector = event.currentTarget.getAttribute('data-target-selector');
                        const selectedCountryCode = event.currentTarget.value;

                        const countryField = form.fields.find((field) =>
                            field.id === event.currentTarget.name,
                        );

                        if (!countryField) return;

                        const defaultCountryValue = countryField.defaultValues?.length ? countryField.defaultValues[0] : '';

                        if (!selectedCountryCode || !isInfContactKeyExist || defaultCountryValue !== selectedCountryCode) {
                            const dropdownInfo = await formInputOptionValues(formId, 'Region', selectedCountryCode);
                            const dropdown = document.querySelectorAll(targetSelector);

                            dropdown[0].options.length = 1;

                            if (!dropdownInfo.allowedValues?.length) {
                                dropdown[0].options[0].value = '';
                                dropdown[0].options[0].innerHTML = 'No states available';
                                dropdown[0].disabled = true;
                            } else {
                                const countryType = formElement[index].options[0].text;

                                if (countryType.includes('billing')) {
                                    dropdown[0].options[0].innerHTML = 'Enter your billing state';
                                }
                                else if (countryType.includes('shipping')) {
                                    dropdown[0].options[0].innerHTML = 'Enter your shipping state';
                                }
                                else {
                                    dropdown[0].options[0].innerHTML = 'Enter your optional state';
                                }

                                dropdown[0].disabled = false;
                            }

                            for (let count = 0; count < dropdownInfo.allowedValues.length; count++) {
                                const option = document.createElement('option');

                                option.value = dropdownInfo.allowedValues[count].value;
                                option.innerHTML = dropdownInfo.allowedValues[count].label;
                                dropdown[0].appendChild(option);
                            }
                        }

                    });
                }
            }
        }
    }

    loadSelectOptions(formInfo, formElement) {
        formInfo.fields.forEach((element) => {
            if (element.inputType === 'Select') {
                for (let index = 0; index < formElement.length; index++) {
                    if (formElement[index]?.name === element.id) {
                        if (element.allowedValues && formElement[index].length === 1) {
                            for (let count = 0; count < element.allowedValues.length; count++) {
                                const option = document.createElement('option');

                                option.value = element.allowedValues[count].value;
                                option.innerHTML = element.allowedValues[count].label;
                                formElement[index].appendChild(option);
                            }
                        }
                    }
                }
            }
        });
    }

    isTypeText(type) {
        return type === 'Text'
            || type === 'Year'
            || type === 'TextArea'
            || type === 'Date'
            || type === 'Email'
            || type === 'Url'
            || type === 'Number'
            || type === 'Currency'
            || type === 'DayOfWeek'
            || type === 'Month'
            || type === 'Name'
            || type === 'WholeNumber'
            || type === 'DecimalNumber'
            || type === 'Percent'
            || type === 'Decimal'
            || type === 'Phone';
    }

    autofillTextField(fieldId, field, useDefaultValue) {
        const elements = document.getElementsByName(fieldId);

        for (let i = 0; i < elements?.length; i++) {
            const element = elements[i];

            element.value = '';

            if (useDefaultValue) {
                element.value = field.defaultValues[0];
                element.dispatchEvent(new Event('change'));
            }
        }
    }

    async handlingAutofillForm(formInfoPromise) {
        try {
            const formInfo = await formInfoPromise;

            // Set default field values
            formInfo.form.fields.forEach((field) => {
                const fieldId = field.id;
                const type = field.inputType;
                const isDefaultValueDefined = field.defaultValues[0] !== undefined;

                if (this.isTypeText(type)) {
                    this.autofillTextField(fieldId, field, isDefaultValueDefined);
                } else if (type === 'Select') {
                    this.autofillSelectField(fieldId, field);
                } else if (type === 'Radio') {
                    this.autofillRadioField(fieldId, field);
                } else if (type === 'Checkboxes') {
                    this.autofillCheckboxesField(fieldId, field);
                }
            });

            // Autofill text fields with values that may be passed in via URL params
            if (this.element.getAttribute('url-params-enabled') === 'true') {
                const params = this.getUrlSearchParams();

                for (let key of params.keys()) {
                    const matchingUrlParamDiv = document.querySelector(`[data-url-param='${key}']`);

                    if (matchingUrlParamDiv) {
                        const matchingUrlParamInputField = matchingUrlParamDiv.querySelector('input');

                        if (matchingUrlParamInputField) {
                            matchingUrlParamInputField.value = params.get(key);
                            matchingUrlParamInputField.dispatchEvent(new Event('change'));
                        }
                    }
                }
            }
        } catch (error) {
            console.log('error: ', error);
        }
    }

    autofillSelectField(fieldId, field) {
        if (field.inputOptionsType !== 'YesNo') {
            this.autofillYesNoSelectField(fieldId, field);
        } else {
            this.autofillAllPurposeSelectField(fieldId, field);
        }
    }

    autofillYesNoSelectField(fieldId, field) {
        // eslint-disable-next-line no-case-declarations
        const elements = document.getElementsByName(fieldId);

        for (let i = 0; i < elements?.length; i++) {
            const optionsObject = elements[i];
            const options = optionsObject.options;

            for (let i = 0; i < options?.length; i++) {
                const option = options[i];
                const defaultValue = field?.defaultValues[0];

                if (((field.inputOptionsType === 'PhoneType' || field.inputOptionsType === 'FaxType') && option.value.toUpperCase() === defaultValue) ||
                    ((field.inputOptionsType === 'Suffix' || field.inputOptionsType === 'Country') && option.value === defaultValue) ||
                    (option.value === defaultValue)) {
                    option.selected = 'selected';
                    optionsObject.dispatchEvent(new Event('change'));
                }
            }
        }
    }

    autofillAllPurposeSelectField(fieldId, field) {
        const radioOptions = document.getElementsByName(fieldId);

        radioOptions.forEach((radio) => {
            const defaultValue = field?.defaultValues[0];

            if (defaultValue === '1' && radio.value === 'true') {
                radio.checked = true;
                radio.dispatchEvent(new Event('change'));
            }

            if (defaultValue === '0' && radio.value === 'false') {
                radio.checked = true;
                radio.dispatchEvent(new Event('change'));
            }
        });
    }

    autofillRadioField(fieldId, field) {
        // eslint-disable-next-line no-case-declarations
        const radios = document.getElementsByName(fieldId);

        for (let i = 0; i < radios.length; i++) {
            const radio = radios[i];

            if (radio.value === field?.defaultValues[0]) {
                radio.parentElement.parentElement.className = 'radio selected';
                radio.dispatchEvent(new Event('change'));
            }
        }
    }

    autofillCheckboxesField(fieldId, field) {
        // eslint-disable-next-line no-case-declarations
        const defaultCheckboxes = field.defaultValues[0];

        // eslint-disable-next-line no-case-declarations
        const checkboxes = document.getElementsByName(fieldId);

        // eslint-disable-next-line no-case-declarations
        const arrayDefaultCheckboxes = defaultCheckboxes?.split(',');

        for (let i = 0; i < checkboxes.length; i++) {
            const checkbox = checkboxes[i];

            if (arrayDefaultCheckboxes?.filter((v) => v == checkbox.value)?.length > 0) {
                checkbox.click();
            }
        }
    }

    getUrlSearchParams() {
        return new URLSearchParams(document.location.search);
    }

    async getRecaptchaToken() {
        if (!this.recaptchaHelperPromise) return null;
        const helper = await this.recaptchaHelperPromise;

        return helper.getRecaptchaToken(RECAPTCHA_ACTION_TOKEN);
    }

    /**
     * Shorthand for dispatching an event from this form.  This will dispatch a bubbling [CustomEvent].
     * @param name The name of the event.
     * @param detail Detail data for the event.
     */
    dispatchEvent(name, detail = {}) {
        this.element.dispatchEvent(new CustomEvent(name, { bubbles: true, detail }));
    }

    /**
   * Checks if any smsOptin field is present. If it's present
   * then it will send the smsOptin property of each phoneNumber as true
   * otherwise it will send smsOptin as false.
   * @param fields The form fields
   */
    addOptInFields(fields) {
        for (var key of Object.keys(fields)) {
            if (key.includes('smsOptin')) {
                if (fields[key]) {
                    fields[key] = 'true';
                } else {
                    fields[key] = 'false';
                }
            }
        }

        return fields;
    }

    /**
     * Returns a copy of all form field values.
     * @return {FormFieldSnapshot}
     */
    currentValues() {
        return this.formState.currentValues;
    }

    isBoolean(n) {
        return typeof n === 'boolean';
    }

    validate() {
        const url = new URL(window.location.href);

        return Object.entries(this.formState.fields)
            .reduce((prev, [key, state]) => {
                let { value, errors = [] } = state.convertAndValidate();

                if (this.isBoolean(value)) {
                    value = value === true ? 1 : 0;
                }

                if (key === 'standard.website') {
                    try {
                        const parsedUrl = new URL(value);

                        value = parsedUrl.origin;
                    } catch (error) {
                        console.warn('Error validating:', error);
                        value = `https://${value}`;
                    }
                }

                if (!value) {
                    const parameterName = snakeCase(state.fieldLabel);
                    const paramValue = url.searchParams.get(parameterName);

                    if (paramValue) {
                        value = paramValue;
                    }
                }

                errors.forEach((err) => prev.errors.push([key, state.fieldLabel ?? state.fieldName, err]));

                if (state.fieldType === 'datetime') {
                    key = state.name.split('_')[0];

                    if (state.inputType === 'date') {
                        prev.values[key] = value;
                    } else {
                        prev.values[key] = `${prev.values[key]}T${value}`;
                    }
                } else {
                    prev.values[key] = value;
                }

                return prev;
            }, { values: {}, errors: [] });
    }

    /**
     * Submits this form to the smart-forms-api
     */
    async submitForm(fields) {
        fields = this.addOptInFields(fields);

        // Process the fields for checking if virtual fields exist.
        const fieldsData = this.processTagsForVirtualFields(fields);

        this.dispatchEvent(FORM_EVENTS.formSubmitStarted, fields);
        this.formState.persist();
        let recaptchaToken;

        try {
            recaptchaToken = await this.getRecaptchaToken();
        } catch (err) {
            amplitude.logEvent(KEAP_EVENT_NAMES.formError, {
                ...this.scopes,
                [KEAP_EVENT_PROPERTIES.formSlug]: this.slug,
                [KEAP_EVENT_PROPERTIES.errorCode]: 'recaptcha.failed',
            });
            this.dispatchEvent(FORM_EVENTS.formSubmitError, { code: 'recaptcha' });

            return { success: false, attempted: false };
        }

        const {
            [FORM_SCOPES.marketingPageSlug]: pageId,
            [FORM_SCOPES.marketingSiteSlug]: siteId,
        } = this.scopes;

        const postData = {
            data: fieldsData,
            sourceType: LANDING_PAGE_SOURCE_TYPE,
            sourceUrl: window.location.href,
            sourceDisplayType: window.top === window
                ? SMART_FORM_DISPLAY_TYPES.HOSTED
                : SMART_FORM_DISPLAY_TYPES.IFRAME,
            pageId,
            siteId,
            visitId: reporting.getVisitId(),
            visitorId: reporting.getVisitorId(),
        };

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        if (queryString.includes('utm_')) {
            if (urlParams.get('utm_source')) postData.utmSource = urlParams.get('utm_source');
            if (urlParams.get('utm_medium')) postData.utmMedium = urlParams.get('utm_medium');
            if (urlParams.get('utm_campaign')) postData.utmCampaign = urlParams.get('utm_campaign');
            if (urlParams.get('utm_term')) postData.utmTerm = urlParams.get('utm_term');
            if (urlParams.get('utm_content')) postData.utmContent = urlParams.get('utm_content');
        }

        try {
            if (!this.formIdPromise) {
                return { attempted: false, success: false };
            }

            const formId = await this.formIdPromise;

            const data = await submitSmartForm({
                formId, recaptchaToken, postData,
            });

            this.dispatchEvent(FORM_EVENTS.formSubmitCompleted, { data });

            amplitude.logEvent(KEAP_EVENT_NAMES.formSubmitted, {
                ...this.scopes,
                [KEAP_EVENT_PROPERTIES.formSlug]: this.slug,
            });

            return {
                success: true,
                data,
                attempted: true,
            };
        } catch (error) {
            console.warn('Error submitting:', error);
            this.dispatchEvent(FORM_EVENTS.formSubmitError, { code: 'smart-forms-api', error });
            amplitude.logEvent(KEAP_EVENT_NAMES.formError, {
                ...this.scopes,
                [KEAP_EVENT_PROPERTIES.formSlug]: this.slug,
                [KEAP_EVENT_PROPERTIES.errorCode]: 'http.error',
            });

            return {
                attempted: true,
                success: false,
                error,
            };
        }
    }

    /**
     * Invoked after successful form submission.
     */
    handleCompletion() {
        if (this.successRedirect) {
            window.open(this.successRedirect, this.successTarget);
        }
    }

    /**
     * Inspects the dom for form fields and adds them to [fields].
     */
    registerFields() {
        const hostingVersion = findHostingVersion(this.formElement) ?? 'default';

        inspectForm(this.formElement, this.formState, { hostingVersion });
        this.dispatchEvent(FORM_EVENTS.formInspectionComplete, this.formState.fields);
    }

    processTagsForVirtualFields(fields) {
        const processedFields = { ...fields };

        const virtualFields = ['standard.virtualField.multiselect', 'standard.virtualField.select', 'standard.virtualField.radio'];

        let hasVirtualField = false;

        virtualFields.forEach((fieldType) => {
            if (processedFields[fieldType]) {
                hasVirtualField = true;

                if (!processedFields['standard.tag']) {
                    processedFields['standard.tag'] = [];
                }

                processedFields[fieldType].forEach((value) => {
                    if (!processedFields['standard.tag'].includes(value)) {
                        processedFields['standard.tag'].push(value);
                    }
                });

                delete processedFields[fieldType];
            }
        });

        if (!processedFields['standard.tag'] && hasVirtualField) {
            processedFields['standard.tag'] = undefined;
        }

        return processedFields;
    }
}

